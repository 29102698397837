import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../styles/Register.css';

const RegisterPage = () => {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        confirmPassword: '',
        role: ''
    });
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    const validateForm = () => {
        let formIsValid = true;
        let errors = {};

        if (!formData.email) {
            formIsValid = false;
            errors['email'] = 'Email is required';
        } else if (!/\S+@\S+\.edu$/.test(formData.email)) {
            formIsValid = false;
            errors['email'] = 'Email must be a .edu address';
        }

        if (!formData.password) {
            formIsValid = false;
            errors['password'] = 'Password is required';
        }

        if (formData.password !== formData.confirmPassword) {
            formIsValid = false;
            errors['confirmPassword'] = 'Passwords do not match';
        }

        if (!formData.role) {
            formIsValid = false;
            errors['role'] = 'Role is required';
        }

        setErrors(errors);
        return formIsValid;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            axios.post('https://nxn3021.uta.cloud/api/register', {
                email: formData.email,
                password: formData.password,
                user_type: formData.role
            }).then(response => {
                console.log('Registration successful:', response.data.message);
                localStorage.setItem('id', response.data.user.id.toString());
                localStorage.setItem('role', response.data.user.user_type);
                localStorage.setItem('isLoggedIn', true);
                if (formData.role === 'graduate') {
                    navigate('/graduate-dashboard');
                } else if (formData.role === 'professor') {
                    navigate('/professor-dashboard');
                }
            }).catch(error => {
                // Handle errors here
                const serverErrors = error.response.data.errors || {};
                setErrors({
                    ...errors,
                    ...serverErrors,
                    email: serverErrors.email || (error.response.data.message || 'An error occurred'),
                });
            });
        }
    };

    return (
        <div className="register-page">
            <form className="register-form" onSubmit={handleSubmit}>
                <h2>Register</h2>
                <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                    />
                    {errors.email && <div className="error">{errors.email}</div>}
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password:</label>
                    <input
                        type="password"
                        id="password"
                        name="password"
                        value={formData.password}
                        onChange={handleInputChange}
                        required
                    />
                    {errors.password && <div className="error">{errors.password}</div>}
                </div>
                <div className="form-group">
                    <label htmlFor="confirmPassword">Confirm Password:</label>
                    <input
                        type="password"
                        id="confirmPassword"
                        name="confirmPassword"
                        value={formData.confirmPassword}
                        onChange={handleInputChange}
                        required
                    />
                    {errors.confirmPassword && <div className="error">{errors.confirmPassword}</div>}
                </div>
                <div className="form-group">
                    <label htmlFor="role">Role:</label>
                    <select
                        id="role"
                        name="role"
                        value={formData.role}
                        onChange={handleInputChange}
                        required
                    >
                        <option value="">Select Role</option>
                        <option value="professor">Professor</option>
                        <option value="graduate">Graduate</option>
                    </select>
                    {errors.role && <div className="error">{errors.role}</div>}
                </div>
                <button type="submit">Register</button>
            </form>
        </div>
    );
};

export default RegisterPage;
