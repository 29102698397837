import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-scroll';
import '../styles/ProfessorDashboard.css';

const ProfessorDashboard = () => {
    const [newTask, setNewTask] = useState({
        title: '',
        description: '',
        deadline: '',
        priority: '',
        assignedStudent: '',
    });
    const [students, setStudents] = useState([]);
    const [reports, setReports] = useState([]);
    const [assignedTasks, setAssignedTasks] = useState([]);
    const [error, setError] = useState('');
    const baseurl = "https://nxn3021.uta.cloud";
    const [allUsers, setAllUsers] = useState([]);

    useEffect(() => {
        fetchUsers();
        fetchAssignedTasks();
        fetchAllUsers();
    }, []);


    useEffect(() => {
        if (allUsers.length > 0) {
            fetchReports();
        }
    }, [allUsers]);

    const fetchAllUsers = async () => {
        try {
            const response = await axios.get(`${baseurl}/api/users`);
            setAllUsers(response.data.users);
        } catch (error) {
            console.error('Failed to fetch all users', error);
            setError('Failed to load all users.');
        }
    };


    const fetchUsers = async () => {
        try {
            const response = await axios.get(`${baseurl}/api/users`);
            const filteredStudents = response.data.users.filter(user => user.user_type === 'graduate');
            setStudents(filteredStudents);
        } catch (error) {
            console.error('Failed to fetch users', error);
            setError('Failed to load users.');
        }
    };

    const fetchAllUsersAndReports = async () => {
        try {
            const usersResponse = await axios.get(`${baseurl}/api/users`);
            setAllUsers(usersResponse.data.users);
            await fetchReports(); // Ensure this call is awaited so allUsers is set first
        } catch (error) {
            console.error('Failed to fetch all users or reports', error);
            setError('Failed to load all users or reports.');
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewTask({ ...newTask, [name]: value });
    };

    const handleAssignTask = () => {
        if (!newTask.title || !newTask.description || !newTask.deadline || !newTask.priority || !newTask.assignedStudent) {
            setError('Please fill in all fields before assigning a task.');
            return;
        }
        axios.post(`${baseurl}/api/tasks`, {
            ...newTask,
            assigned_to: newTask.assignedStudent
        }).then(response => {
            fetchAssignedTasks();
            setNewTask({ title: '', description: '', deadline: '', priority: '', assignedStudent: '' });
            setError('');
        }).catch(error => {
            console.error('Failed to assign task', error);
            setError('Failed to assign task. Please try again.');
        });
    };
    const handleFeedbackChange = (id, feedback) => {
        const updatedReports = reports.map(report => {
            if (report.id === id) {
                return { ...report, feedback };
            }
            return report;
        });
        setReports(updatedReports);
    };
    const handleSubmitFeedback = (id) => {
        const feedback = reports.find(report => report.id === id).feedback;
        axios.put(`${baseurl}/api/reports/${id}`, { feedback })
            .then(response => {
                setError('Feedback submitted successfully.');
            })
            .catch(error => {
                console.error('Failed to submit feedback', error);
                setError('Failed to submit feedback.');
            });
    };

    const fetchAssignedTasks = () => {
        axios.get(`${baseurl}/api/tasks`)
            .then(response => {
                const tasksWithUserDetails = response.data.tasks.map(task => ({
                    ...task,
                    studentEmail: task.user.email
                }));
                setAssignedTasks(tasksWithUserDetails);
            })
            .catch(error => {
                console.error('Failed to fetch tasks', error);
                setError('Failed to load tasks.');
            });
    };


    const fetchReports = async () => {
        try {
            const reportsResponse = await axios.get(`${baseurl}/api/reports`);
            const formattedReports = reportsResponse.data.reports.map(report => {
                const submittingUser = allUsers.find(user => user.id === report.submitted_by);
                return {
                    ...report,
                    submissionDate: new Date(report.submission_date).toLocaleDateString(),
                    feedback: report.feedback || '',
                    studentEmail: submittingUser ? submittingUser.email : 'Unknown'
                };
            });
            setReports(formattedReports);
        } catch (error) {
            console.error('Failed to fetch reports', error);
            setError('Failed to load reports.');
        }
    };

    return (
        <div className="professor-dashboard">
            <h1>Professor Dashboard</h1>
            <div className="navigation-buttons">
                <Link to="review-reports" smooth={true} duration={500}>Review Reports</Link>
                <Link to="assign-task" smooth={true} duration={500}>Assign Task</Link>
                <Link to="view-assigned-tasks" smooth={true} duration={500}>View Assigned Tasks</Link>
                <Link to="view-weekly-reports" smooth={true} duration={500}>View Weekly Reports</Link>
            </div>
            <div className="report-review" id="review-reports">
                <h2>Review Reports</h2>
                <div className="reports">
                    {reports.map(report => (
                        <div key={report.id} className="report">
                            <div className="report-header">
                                <h3>{report.student}</h3>
                            </div>
                            <h3>Submitted by: {report.studentEmail}</h3>

                            <p className="summary">Summary: {report.content}</p>
                            <span className="hours">Hours Justification: {report.justification} hours</span>

                            <textarea
                                className="feedback"
                                placeholder="Provide feedback"
                                value={report.feedback}
                                onChange={(e) => handleFeedbackChange(report.id, e.target.value)}
                            ></textarea>
                            <button onClick={() => handleSubmitFeedback(report.id)}>Submit Feedback</button>
                        </div>
                    ))}
                </div>
            </div>

            <div className="task-assignment" id="assign-task">
                <h2>Assign Task</h2>
                <form>
                    <label htmlFor="task-title">Task Title:</label>
                    <input
                        type="text"
                        id="task-title"
                        name="title"
                        value={newTask.title}
                        onChange={handleInputChange}
                    />
                    <label htmlFor="task-description">Description:</label>
                    <textarea
                        id="task-description"
                        name="description"
                        value={newTask.description}
                        onChange={handleInputChange}
                    ></textarea>
                    <label htmlFor="task-deadline">Deadline:</label>
                    <input
                        type="date"
                        id="task-deadline"
                        name="deadline"
                        value={newTask.deadline}
                        onChange={handleInputChange}
                    />
                    <label htmlFor="task-priority">Priority:</label>
                    <select
                        id="task-priority"
                        name="priority"
                        value={newTask.priority}
                        onChange={handleInputChange}
                    >
                        <option value="">Select Priority</option>
                        <option value="High">High</option>
                        <option value="Medium">Medium</option>
                        <option value="Low">Low</option>
                    </select>
                    <label htmlFor="task-student">Assigned Student:</label>
                    <select
                        id="task-student"
                        name="assignedStudent"
                        value={newTask.assignedStudent}
                        onChange={handleInputChange}
                    >
                        <option value="">Select Student</option>
                        {students.map(student => (
                            <option key={student.id} value={student.id}>{student.email}</option>
                        ))}
                    </select>
                    <button type="button" onClick={handleAssignTask}>Assign</button>
                </form>
            </div>

            <div id="view-assigned-tasks">
                <h2>View Assigned Tasks</h2>
                <div className="assigned-tasks">
                    {assignedTasks.map(task => (
                        <div key={task.id} className="task-card">
                            <div className="task-header">
                                <h3>{task.title}</h3>
                                <span className={`priority ${task.priority.toLowerCase()}`}>{task.priority}</span>
                            </div>
                            <div className="task-body">
                                <p><strong>Deadline:</strong> {task.deadline}</p>
                                <p><strong>Assigned to:</strong> {task.studentEmail}</p>
                            </div>

                            <div className="task-footer">
                                <button className="complete-btn" style={{ backgroundColor: task.status === 'pending' ? 'gray' : 'green', color: task.status === 'pending' ? 'white' : 'white' }} disabled={task.status !== 'completed'}>{task.status === 'pending' ? 'Pending' : 'Completed'}</button>
                            </div>

                        </div>
                    ))}
                </div>
            </div>


            <div className="weekly-reports">
                {reports.map(report => (
                    <div key={report.id} className="report">
                        <h3>Submitted by: {report.studentEmail}</h3>
                        <p>Hours: {report.justification}</p>
                        <p>Submission Date: {report.submissionDate}</p>
                        <p>Report: {report.content}</p>
                        {report.feedback && <p>Feedback: {report.feedback}</p>}
                        {report.file_path && (
                            <a href={`${baseurl}/storage/${report.file_path}`} download>
                                <button className="download-button">Download File</button>
                            </a>
                        )}
                    </div>
                ))}
            </div>

        </div>
    );
};

export default ProfessorDashboard;
