import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/Messages.css';

const Messages = () => {
    const [contacts, setContacts] = useState([]);
    const [selectedContact, setSelectedContact] = useState(null);
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const id = localStorage.getItem('id');

    const handleSelectContact = (contact) => {
        setSelectedContact(contact);
        fetchMessages(id, contact.id);
    };

    const handleMessageChange = (e) => {
        setMessage(e.target.value);
    };
    const baseurl = ""

    const handleSendMessage = async () => {
        if (selectedContact && message.trim()) {
            try {
                // Send the message to the server
                const response = await axios.post('https://sbp2714.uta.cloud/api/post-message', {
                    senderId: id,
                    receiverId: selectedContact.id,
                    message: message
                });

                setMessages(response.data);
                setMessage('');
            } catch (error) {
                console.error('Error sending message:', error);
            }
        }
    };

    const fetchMessages = async (senderId, receiverId) => {
        try {
            const response = await axios.post('https://sbp2714.uta.cloud/api/fetch-messages', {
                senderId: senderId,
                receiverId: receiverId
            });
            setMessages(response.data);
        } catch (error) {
            console.error('Failed to fetch messages:', error);
            setMessages([]);
        }
    };


    useEffect(() => {
        const fetchContacts = async () => {
            try {
                const response = await axios.get('https://sbp2714.uta.cloud/api/users');
                console.log("Response from the API", response);
                const filteredContacts = response.data.filter(user => user.id !== id);
                setContacts(filteredContacts.map(user => ({
                    id: user.id,
                    name: user.email
                })));
            } catch (error) {
                console.error('Failed to fetch contacts:', error);
            }
        };

        fetchContacts();
    }, []);

    return (
        <div className="messages-page">
            <div className="contacts-list">
                {contacts.map(contact => (
                    <div key={contact.id} className="contact" onClick={() => handleSelectContact(contact)}>
                        <h3>{contact.name}</h3>
                    </div>
                ))}
            </div>

            <div className="chat-window">
                {selectedContact ? (
                    <>
                        <h2>{selectedContact.name}</h2>
                        <div className="chat-messages">
                            {messages.map(msg => (
                                <div key={msg.message_id} className={Number(msg.sender_id) === Number(id) ? "message-sent" : "message-received"}>
                                    <p>{msg.message_text}</p>
                                </div>
                            ))}
                        </div>
                        <div className="message-input">
                            <input type="text" value={message} onChange={handleMessageChange} />
                            <button onClick={handleSendMessage}>Send</button>
                        </div>
                    </>
                ) : (
                    <p>Select a contact to start chatting</p>
                )}
            </div>

        </div>
    );
};

export default Messages;