import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../styles/Login.css';

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleLogin = (e) => {
        e.preventDefault();
        setError(''); // Clear previous errors 

        axios.post('https://nxn3021.uta.cloud/api/login', { email, password })
            .then(response => {
                const { id, user_type } = response.data.user;
                localStorage.setItem('id', id.toString());
                localStorage.setItem('isLoggedIn', 'true');
                localStorage.setItem('role', user_type);

                // Navigate based on user type
                if (user_type === 'graduate') {
                    navigate('/graduate-dashboard');
                } else if (user_type === 'professor') {
                    navigate('/professor-dashboard');
                }
            })
            .catch(error => {
                // Handle errors returned by the server
                if (error.response && error.response.data) {
                    setError(error.response.data.message || 'Invalid credentials');
                } else {
                    setError('Login failed. Please try again.');
                }
            });
    };

    return (
        <div className="login-page">
            <form className="login-form" onSubmit={handleLogin}>
                <h2>Login</h2>
                <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password:</label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                {error && <div className="error-message">{error}</div>}
                <button type="submit">Login</button>
                <div className="login-options">
                    <Link to="/forgot-password">Forgot Password?</Link>
                    <Link to="/register">Register</Link>
                </div>
            </form>
        </div>
    );
};

export default LoginPage;
