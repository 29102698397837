import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/Navbar.css';

const Navbar = () => {
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userRole, setUserRole] = useState('');

    useEffect(() => {
        const checkLoginStatus = () => {
            const loggedInStatus = localStorage.getItem('isLoggedIn') === 'true';
            setIsLoggedIn(loggedInStatus);
            const role = localStorage.getItem('role');
            setUserRole(role);
        };

        checkLoginStatus();
        const intervalId = setInterval(checkLoginStatus, 1000);

        return () => clearInterval(intervalId);
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('isLoggedIn');
        localStorage.removeItem('id');
        localStorage.removeItem('role');
        setIsLoggedIn(false);
        navigate('/');
        window.location.reload();
    };

    const dashboardLink = userRole === 'graduate' ? '/graduate-dashboard' : '/professor-dashboard';

    return (
        <nav className="navbar">
            <div className="nav-container">
                <Link to="/" className="nav-logo">Home</Link>
                <ul className="nav-menu">
                    <li className="nav-item">
                        <Link to="https://bxn9530.uta.cloud/" className="nav-link">Blog</Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/services" className="nav-link">Services</Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/about-us" className="nav-link">About Us</Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/contact-us" className="nav-link">Contact Us</Link>
                    </li>
                    {isLoggedIn ? (
                        <>
                            <li className="nav-item">
                                <Link to={dashboardLink} className="nav-link">Dashboard</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/messages" className="nav-link">Messages</Link>
                            </li>
                            <li className="nav-item">
                                <a href="#" className="nav-link" onClick={handleLogout}>Log out</a>
                            </li>
                        </>
                    ) : (
                        <>
                            <li className="nav-item">
                                <Link to="/login" className="nav-link">Login</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/register" className="nav-link">Register</Link>
                            </li>
                        </>
                    )}
                </ul>
            </div>
        </nav>
    );
};

export default Navbar;
