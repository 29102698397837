// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import Calendar from 'react-calendar';
// import 'react-calendar/dist/Calendar.css';
// import '../styles/GraduateDashboard.css';

// const GraduateDashboard = () => {
//     const [tasks, setTasks] = useState([]);
//     const [date, setDate] = useState(new Date());
//     const [loading, setLoading] = useState(false);
//     const [error, setError] = useState('');
//     const [reports, setReports] = useState([]);
//     const [content, setContent] = useState('');
//     const [reportResult, setReportResult] = useState('');
//     const [apiResult, setApiResult] = useState(null);

//     const baseurl = "https://nxn3021.uta.cloud";
//     useEffect(() => {
//         fetchTasks();
//         fetchReports();
//     }, []);
//     const checkContent = () => {
//         axios.post('https://nxn3021.uta.cloud/api/checkai', { content })
//             .then(response => {
//                 setApiResult(response.data);
//             })
//             .catch(error => {
//                 console.error('API call failed', error);
//                 setError('Failed to get report.');
//             });
//     };





//     const fetchReports = () => {
//         const storedUserId = parseInt(localStorage.getItem('id'));

//         const checkContent = () => {
//             axios.post('https://nxn3021.uta.cloud/api/checkai', { content })
//                 .then(response => {
//                     setReportResult(JSON.stringify(response.data));
//                 })
//                 .catch(error => {
//                     console.error('API call failed', error);
//                     setError('Failed to get report.');
//                 });
//         };

//         axios.get(`${baseurl}/api/reports`)
//             .then(response => {
//                 const formattedReports = response.data.reports
//                     .filter(report => report.submitted_by === storedUserId)
//                     .map(report => ({
//                         ...report,
//                         submissionDate: new Date(report.submission_date).toLocaleDateString(),
//                         feedback: report.feedback || ''
//                     }));
//                 setReports(formattedReports);
//             })
//             .catch(error => {
//                 console.error('Failed to fetch reports', error);
//                 setError('Failed to load reports.');
//             });
//     };

//     const fetchTasks = () => {
//         setLoading(true);
//         const userId = localStorage.getItem('id');
//         axios.get(`${baseurl}/api/tasks`)
//             .then(response => {
//                 const filteredTasks = response.data.tasks.filter(task => task.assigned_to.toString() === userId);
//                 setTasks(filteredTasks);
//                 setLoading(false);
//             })
//             .catch(error => {
//                 console.error('Failed to fetch tasks', error);
//                 setError('Failed to load tasks.');
//                 setLoading(false);
//             });
//     };

//     const handleCompleteTask = (taskId) => {
//         axios.put(baseurl + `/api/tasks/${taskId}`, { status: 'completed' })
//             .then(response => {
//                 fetchTasks();
//             })
//             .catch(error => {
//                 console.error('Failed to update task', error);
//                 setError('Failed to update task status.');
//             });
//     };

//     const handleSubmitReport = (event) => {
//         event.preventDefault();
//         const reportSummary = event.target.reportSummary.value;
//         const hoursJustification = event.target.hoursJustification.value;
//         const submissionDate = new Date().toISOString().slice(0, 19).replace('T', ' ');
//         setLoading(true);
//         axios.post(baseurl + '/api/reports', {
//             submitted_by: localStorage.getItem('id'),
//             content: reportSummary,
//             justification: hoursJustification,
//             submission_date: submissionDate,
//         }).then(response => {
//             setLoading(false);
//             alert('Report submitted successfully!');
//             window.location.reload();
//         }).catch(error => {
//             console.error('Failed to submit report', error);
//             setError('Failed to submit report.');
//             setLoading(false);
//         });
//     };

//     return (
//         <div className="dashboard">
//             <h1>Graduate Dashboard</h1>
//             {error && <div className="error-message">{error}</div>}
//             <div className="progress-overview">
//                 <h2>Progress Overview</h2>
//                 <p>Completed Tasks: {tasks.filter(task => task.completed).length}/{tasks.length}</p>
//             </div>
//             <div className="calendar-section">
//                 <h2>Calendar</h2>
//                 <Calendar
//                     onChange={setDate}
//                     value={date}
//                     tileClassName={({ date, view }) => {
//                         if (view === 'month') {
//                             const formattedDate = date.toDateString();
//                             const isDeadline = tasks.some(task => {
//                                 const deadlineDate = new Date(task.deadline);
//                                 deadlineDate.setDate(deadlineDate.getDate() + 1);
//                                 return deadlineDate.toDateString() === formattedDate;
//                             });
//                             if (isDeadline) {
//                                 return 'deadline';
//                             }
//                         }
//                     }}
//                 />

//                 <p>Selected Date: {date.toDateString()}</p>
//             </div>
//             <div className="assigned-tasks">
//                 <h2>Assigned Tasks</h2>
//                 <ul>
//                     {tasks.length === 0 ? (
//                         <p>No tasks are assigned.</p>
//                     ) : (
//                         tasks.map(task => (
//                             <li key={task.id}>
//                                 {task.priority} - {task.description} (Deadline: {task.deadline})
//                                 <button onClick={() => handleCompleteTask(task.id, task.status)}>
//                                     {task.status === 'completed' ? 'Completed' : 'Mark as Complete'}
//                                 </button>
//                             </li>
//                         ))
//                     )}
//                 </ul>
//             </div>
//             <div className="content-check">
//                 <h2>Check Content</h2>
//                 <textarea
//                     value={content}
//                     onChange={(e) => setContent(e.target.value)}
//                     placeholder="Enter content to check"
//                     rows="4"
//                     className="content-textarea"
//                 ></textarea>
//                 <button onClick={checkContent} className="check-button">Check</button>
//                 {apiResult && (
//                     <div className="api-result">
//                         <h3>Scan Summary</h3>
//                         <p><strong>Disclaimer:</strong> {apiResult.disclaimer}</p>
//                         <p><strong>Original Score:</strong> {apiResult.score.original.toFixed(4)}</p>
//                         <p><strong>AI Score:</strong> {apiResult.score.ai.toFixed(4)}</p>
//                         <p><strong>Report Link:</strong> <a href={apiResult.public_link} target="_blank" rel="noopener noreferrer">View Full Report</a></p>
//                         {apiResult.blocks.map((block, index) => (
//                             <div key={index} className="block-result">
//                                 <p>{block.text}</p>
//                                 <p><strong>Fake:</strong> {block.result.fake.toFixed(4)}</p>
//                                 <p><strong>Real:</strong> {block.result.real.toFixed(4)}</p>
//                             </div>
//                         ))}
//                     </div>
//                 )}
//             </div>
//             <div className="weekly-reports">
//                 <h2>Weekly Reports</h2>
//                 {loading ? (
//                     <div>Loading...</div>
//                 ) : error ? (
//                     <div>Error: {error}</div>
//                 ) : (
//                     <>
//                         <div className="reports-container">
//                             {reports.length > 0 ? reports.map((report, index) => (
//                                 <div key={report.id} className={`report-item report-item-${index % 2 === 0 ? 'even' : 'odd'}`} style={{ border: '1px solid #ccc', margin: '10px', padding: '10px' }}>
//                                     <h3>Report {index + 1}</h3>
//                                     <p><strong>Report Content:</strong> {report.content}</p>
//                                     <p><strong>Justification:</strong> {report.justification}</p>
//                                     <p><strong>Submission Date:</strong> {report.submissionDate}</p>
//                                     {report.feedback && <p><strong>Feedback:</strong> {report.feedback}</p>}
//                                 </div>
//                             )) : (
//                                 <p>No reports available.</p>
//                             )}
//                         </div>
//                         <form onSubmit={handleSubmitReport}>
//                             <label htmlFor="reportSummary">Report Summary:</label>
//                             <textarea id="reportSummary" name="reportSummary" rows="4"></textarea>
//                             <label htmlFor="hoursJustification">Justification for 21 Hours:</label>
//                             <textarea id="hoursJustification" name="hoursJustification" rows="2"></textarea>
//                             <label htmlFor="attachments">Upload Attachments:</label>
//                             <input type="file" id="attachments" name="attachments" accept=".pdf,.doc,.docx" />
//                             <button type="submit">Submit Report</button>
//                         </form>
//                     </>
//                 )}
//             </div>


//         </div>
//     );
// };

// export default GraduateDashboard;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import '../styles/GraduateDashboard.css';

const GraduateDashboard = () => {
    const [tasks, setTasks] = useState([]);
    const [date, setDate] = useState(new Date());
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [reports, setReports] = useState([]);
    const [content, setContent] = useState('');
    const [reportResult, setReportResult] = useState('');
    const [apiResult, setApiResult] = useState(null);

    const baseurl = "https://nxn3021.uta.cloud";
    useEffect(() => {
        fetchTasks();
        fetchReports();
    }, []);
    const checkContent = () => {
        axios.post('https://nxn3021.uta.cloud/api/checkai', { content })
            .then(response => {
                setApiResult(response.data);
            })
            .catch(error => {
                console.error('API call failed', error);
                setError('Failed to get report.');
            });
    };

    const fetchReports = () => {
        const storedUserId = parseInt(localStorage.getItem('id'));
        axios.get(`${baseurl}/api/reports`)
            .then(response => {
                const formattedReports = response.data.reports
                    .filter(report => report.submitted_by === storedUserId)
                    .map(report => ({
                        ...report,
                        submissionDate: new Date(report.submission_date).toLocaleDateString(),
                        feedback: report.feedback || ''
                    }));
                setReports(formattedReports);
            })
            .catch(error => {
                console.error('Failed to fetch reports', error);
                setError('Failed to load reports.');
            });
    };

    const handleCompleteTask = (taskId) => {
        axios.put(baseurl + `/api/tasks/${taskId}`, { status: 'completed' })
            .then(response => {
                fetchTasks();
            })
            .catch(error => {
                console.error('Failed to update task', error);
                setError('Failed to update task status.');
            });
    };

    const fetchTasks = () => {
        setLoading(true);
        const userId = localStorage.getItem('id');
        axios.get(`${baseurl}/api/tasks`)
            .then(response => {
                const filteredTasks = response.data.tasks.filter(task => task.assigned_to.toString() === userId);
                setTasks(filteredTasks);
                setLoading(false);
            })
            .catch(error => {
                console.error('Failed to fetch tasks', error);
                setError('Failed to load tasks.');
                setLoading(false);
            });
    };

    const handleSubmitReport = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const file = event.target.file.files[0];
        if (!file || !(file.type === "application/pdf" || file.type === "application/msword" || file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document")) {
            alert('Please upload a valid .pdf or .doc/.docx file.');
            return;
        }

        formData.append('submitted_by', localStorage.getItem('id'));
        formData.append('submission_date', new Date().toISOString().slice(0, 19).replace('T', ' '));

        setLoading(true);
        axios.post(baseurl + '/api/reports', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => {
            setLoading(false);
            alert('Report submitted successfully!');
            window.location.reload();
        }).catch(error => {
            console.error('Failed to submit report', error);
            setError('Failed to submit report.');
            setLoading(false);
        });
    };

    return (
        <div className="dashboard">
            <h1>Graduate Dashboard</h1>
            {error && <div className="error-message">{error}</div>}
            <div className="progress-overview">
                <h2>Progress Overview</h2>
                <p>Completed Tasks: {tasks.filter(task => task.completed).length}/{tasks.length}</p>
            </div>
            <div className="calendar-section">
                <Calendar
                    onChange={setDate}
                    value={date}
                    tileClassName={({ date, view }) => {
                        if (view === 'month') {
                            const formattedDate = date.toDateString();
                            const isDeadline = tasks.some(task => {
                                const deadlineDate = new Date(task.deadline);
                                deadlineDate.setDate(deadlineDate.getDate() + 1);
                                return deadlineDate.toDateString() === formattedDate;
                            });
                            if (isDeadline) {
                                return 'deadline';
                            }
                        }
                    }}
                />
                <p>Selected Date: {date.toDateString()}</p>
            </div>
            <div className="assigned-tasks">
                <h2>Assigned Tasks</h2>
                <ul>
                    {tasks.length === 0 ? (
                        <p>No tasks are assigned.</p>
                    ) : (
                        tasks.map(task => (
                            <li key={task.id

                            }>
                                {task.priority} - {task.description} (Deadline: {task.deadline})
                                <button onClick={() => handleCompleteTask(task.id, task.status)}>
                                    {task.status === 'completed' ? 'Completed' : 'Mark as Complete'}
                                </button>
                            </li>
                        ))
                    )}
                </ul>
            </div>
            {/* <div className="weekly-reports">
                <h2>Weekly Reports</h2>
                {loading ? (
                    <div>Loading...</div>
                ) : error ? (
                    <div>Error: {error}</div>
                ) : (
                    <>
                        <div className="reports-container">
                            {reports.length > 0 ? reports.map((report, index) => (
                                <div key={report.id} className={`report-item report-item-${index % 2 === 0 ? 'even' : 'odd'}`} style={{ border: '1px solid #ccc', margin: '10px', padding: '10px' }}>
                                    <h3>Report {index + 1}</h3>
                                    <p><strong>Report Content:</strong> {report.content}</p>
                                    <p><strong>Justification:</strong> {report.justification}</p>
                                    <p><strong>Submission Date:</strong> {report.submissionDate}</p>
                                    {report.feedback && <p><strong>Feedback:</strong> {report.feedback}</p>}
                                </div>
                            )) : (
                                <p>No reports available.</p>
                            )}
                        </div>
                        <form onSubmit={handleSubmitReport}>
                            <label htmlFor="content">Report Summary:</label>
                            <textarea id="content" name="content" rows="4"></textarea>
                            <label htmlFor="justification">Justification for 21 Hours:</label>
                            <textarea id="justification" name="justification" rows="2"></textarea>
                            <label htmlFor="file">Upload file:</label>
                            <input type="file" id="file" name="file" accept=".pdf,.doc,.docx" />
                            <button type="submit">Submit Report</button>
                        </form>
                    </>
                )}
            </div> */}

            <div className="content-check">
                <h2>Check Content</h2>
                <textarea
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                    placeholder="Enter content to check"
                    rows="4"
                    className="content-textarea"
                ></textarea>
                <button onClick={checkContent} className="check-button">Check</button>
                {apiResult && (
                    <div className="api-result">
                        <h3>Scan Summary</h3>
                        <p><strong>Disclaimer:</strong> {apiResult.disclaimer}</p>
                        <p><strong>Original Score:</strong> {apiResult.score.original.toFixed(4)}</p>
                        <p><strong>AI Score:</strong> {apiResult.score.ai.toFixed(4)}</p>
                        <p><strong>Report Link:</strong> <a href={apiResult.public_link} target="_blank" rel="noopener noreferrer">View Full Report</a></p>
                        {apiResult.blocks.map((block, index) => (
                            <div key={index} className="block-result">
                                <p>{block.text}</p>
                                <p><strong>Fake:</strong> {block.result.fake.toFixed(4)}</p>
                                <p><strong>Real:</strong> {block.result.real.toFixed(4)}</p>
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <div className="weekly-reports">
                <h2>Weekly Reports</h2>
                {loading ? (
                    <div>Loading...</div>
                ) : error ? (
                    <div>Error: {error}</div>
                ) : (
                    <>
                        <div className="reports-container">
                            {reports.length > 0 ? reports.map((report, index) => (
                                <div key={report.id} className={`report-item report-item-${index % 2 === 0 ? 'even' : 'odd'}`} style={{ border: '1px solid #ccc', margin: '10px', padding: '10px' }}>
                                    <h3>Report {index + 1}</h3>
                                    <p><strong>Report Content:</strong> {report.content}</p>
                                    <p><strong>Justification:</strong> {report.justification}</p>
                                    <p><strong>Submission Date:</strong> {report.submissionDate}</p>
                                    {report.feedback && <p><strong>Feedback:</strong> {report.feedback}</p>}
                                    {report.file_path && (
                                        <a href={`${baseurl}/storage/${report.file_path}`} download>
                                            <button>Download Report</button>
                                        </a>
                                    )}
                                </div>
                            )) : (
                                <p>No reports available.</p>
                            )}
                        </div>
                        <form onSubmit={handleSubmitReport}>
                            <label htmlFor="content">Report Summary:</label>
                            <textarea id="content" name="content" rows="4"></textarea>
                            <label htmlFor="justification">Justification for 21 Hours:</label>
                            <textarea id="justification" name="justification" rows="2"></textarea>
                            <label htmlFor="file">Upload file:</label>
                            <input type="file" id="file" name="file" accept=".pdf,.doc,.docx" />
                            <button type="submit">Submit Report</button>
                        </form>
                    </>
                )}
            </div>

        </div>
    );
};

export default GraduateDashboard;